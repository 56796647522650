exports.components = {
  "component---src-pages-akce-js": () => import("./../../../src/pages/akce.js" /* webpackChunkName: "component---src-pages-akce-js" */),
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nf-vidzin-js": () => import("./../../../src/pages/nf-vidzin.js" /* webpackChunkName: "component---src-pages-nf-vidzin-js" */),
  "component---src-pages-ozamku-js": () => import("./../../../src/pages/ozamku.js" /* webpackChunkName: "component---src-pages-ozamku-js" */),
  "component---src-pages-pronajemaubytovani-js": () => import("./../../../src/pages/pronajemaubytovani.js" /* webpackChunkName: "component---src-pages-pronajemaubytovani-js" */),
  "component---src-pages-svatby-js": () => import("./../../../src/pages/svatby.js" /* webpackChunkName: "component---src-pages-svatby-js" */)
}

